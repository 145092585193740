import * as React from "react";
import { styled } from "styled-components";
import FaqItem from "./FaqItem";

const FaqList = (data: any) => {
  return (
    <FaqListWrapper>
      {data?.data?.map((el: any, idx: number) => (
        <FaqItem key={idx} ask={el.ask} answer={el.answer} />
      ))}
    </FaqListWrapper>
  );
};

const FaqListWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  padding-bottom: 100px;

  @media screen and (max-width: 1220px) {
    width: 100%;
  }
`;

export default FaqList;
