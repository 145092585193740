import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import useDeviceSize from "../hooks/useDeviceSize";
import Sidebar from "../img/mobile/sidebar.png";
import X from "../img/mobile/x.png";
import Active from "../img/web/header/active.png";
import Logo from "../img/web/logo.png";

const Header = () => {
  const { pathname } = useLocation();
  const { isMobile } = useDeviceSize();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <HeaderWrapper>
      {!isMobile ? (
        <HeaderContentWrapper>
          <Link to="/">
            <img src={Logo} alt="logo" width="120px" />
          </Link>

          <div>
            <Link
              to={"/notice"}
              className={`icon ${pathname.includes("/notice") ? "active" : ""}`}
            >
              공지사항
              <img src={Active} width="5px" height="7px" alt="" />
            </Link>

            <Link
              to={"/faqs/auth"}
              className={`icon ${pathname.includes("/faqs") ? "active" : ""}`}
            >
              자주 묻는 질문
              <img
                src={Active}
                width="5px"
                height="7px"
                alt="icon"
                className="icon"
              />
            </Link>
            <Link to={"http://pf.kakao.com/_Jnhxeb"} target="_blank">
              고객센터
              <img
                src={Active}
                width="5px"
                height="7px"
                alt="icon"
                className="icon"
              />
            </Link>
          </div>
        </HeaderContentWrapper>
      ) : (
        <MobileHeader>
          <Link to="/">
            <img src={Logo} alt="logo" width="90px" />
          </Link>

          <button onClick={toggleMenu}>
            {isMenuOpen ? (
              <img src={Sidebar} alt="logo" width="24px" />
            ) : (
              <img src={Sidebar} alt="logo" width="24px" />
            )}
          </button>

          {isMenuOpen && (
            <MenuModal>
              <div>
                <Link to="/" onClick={toggleMenu}>
                  <img src={Logo} alt="logo" width="90px" />
                </Link>

                <button onClick={toggleMenu}>
                  <img src={X} alt="logo" width="20px" />
                </button>
              </div>

              <Link to="/notice" onClick={toggleMenu}>
                <span className="">공지사항</span>
              </Link>

              <Link to="/faqs/auth" onClick={toggleMenu}>
                <span className="">자주묻는질문</span>
              </Link>

              <Link to="http://pf.kakao.com/_Jnhxeb" onClick={toggleMenu}>
                <span className="">고객센터</span>
              </Link>
            </MenuModal>
          )}
        </MobileHeader>
      )}
    </HeaderWrapper>
  );
};

const MenuModal = styled.div`
  background-color: #f3f5f9;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  > div {
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > button {
      border: 0;
      background-color: #f3f5f9;
    }
  }

  > a {
    text-decoration: none;
    color: #6a6f75;
  }

  > a + a {
    margin-top: 16px;
  }
`;

const MobileHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  background: #fff;
  border: 0;
  position: fixed;
  top: 0;

  > button {
    border: 0;
    background: none;
  }
`;

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #ebeef1;
  background-color: #fff;
  z-index: 200;

  @media screen and (max-width: 1220px) {
    padding: 0;
    width: 100%;
    border: 0;
    background-color: #fff;
  }
`;

const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 0;
  max-width: 1200px;
  margin: auto;

  > div {
    display: flex;

    > a {
      text-decoration: none;
      color: #6a6f75;
      font-size: 20px;
      padding: 20px 10px;
      display: flex;
      width: 140px;
      justify-content: center;
      align-items: flex-start;

      &:not(:hover) {
        color: #6a6f75;
        font-weight: 500;

        > img {
          display: none;
        }
      }

      &:hover {
        color: #2c2f32;
        font-weight: 600;
      }

      &.active {
        color: #2c2f32;
        font-weight: 600;

        > img {
          display: flex;
        }
      }
    }

    > a + a {
      margin-left: 10px;
    }
  }

  > a {
    padding: 20px 10px;
  }
  @media screen and (max-width: 1220px) {
    background-color: #fff;
  }
`;

export default Header;
