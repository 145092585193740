import * as React from "react";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import FaqList from "./components/FaqList";
import Tabs from "./components/Tabs";
import {
  authData,
  communityData,
  payData,
  reportData,
  serviceData,
} from "./data/faqsData";

const Faqs = () => {
  const { pathname } = useLocation();

  const faqListData = () => {
    if (pathname === "/faqs/community") {
      return communityData;
    }

    if (pathname === "/faqs/service") {
      return serviceData;
    }

    if (pathname === "/faqs/pay") {
      return payData;
    }

    if (pathname === "/faqs/auth") {
      return authData;
    }

    if (pathname === "/faqs/report") {
      return reportData;
    } else {
      return [...communityData, ...serviceData, ...payData, ...reportData];
    }
  };

  return (
    <FaqsWrapper>
      <h1>자주 묻는 질문</h1>
      <Tabs />
      <FaqList data={faqListData()} />
    </FaqsWrapper>
  );
};

const FaqsWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 200px;
  max-width: 1200px;
  margin: auto;

  > h1 {
    color: #2c2f32;
    font-size: 48px;
  }

  @media screen and (max-width: 1220px) {
    padding: 0;
    padding: 0 16px;
    box-sizing: border-box;

    > h1 {
      font-size: 24px;
      padding: 0;
      padding-top: 100px;
    }
  }
`;

export default Faqs;
