import * as React from "react";
import { styled } from "styled-components";
import { NoticeData } from "../../../api/notice/type";
import NoticeItem from "./NoticeItem";

interface NoticeListProps {
  data: NoticeData[];
}

const NoticeList = ({ data }: NoticeListProps) => {
  return (
    <NoticeListWrapper>
      <h1>&nbsp;&nbsp; 공지사항</h1>

      {data?.length !== 0 ? (
        data?.map((el: NoticeData) => (
          <NoticeItem
            notice_title={el.notice_title}
            date={el.date}
            objectId={el.objectId}
          />
        ))
      ) : (
        <EmptyWrapper>데이터가 없습니다</EmptyWrapper>
      )}
    </NoticeListWrapper>
  );
};
export default NoticeList;

const EmptyWrapper = styled.div`
  padding: 20px 20px 200px 20px;
`;

const NoticeListWrapper = styled.div`
  width: 100%;
  height: 100%;

  > h1 {
    color: #2c2f32;
    font-size: 48px;
    text-align: left;
    width: 100%;
    border-bottom: 0.5px solid #d1d4d6;
    padding-bottom: 20px;
  }

  > a {
    margin-top: 16px;
  }

  @media screen and (max-width: 1220px) {
    > h1 {
      font-size: 24px;
      padding-bottom: 10px;
    }

    > a {
      padding: 8px 20px;
      margin: 16px;
    }
  }
`;
