import axios from "axios";

const axiosAuthConfig = {
  baseURL: "https://api.npercent.kr",
};

const authAPI = axios.create(axiosAuthConfig);

// interceptors
authAPI.interceptors.request.use(
  (res) => {
    return res;
  },

  async (error) => {
    return Promise.reject(error);
  }
);

authAPI.interceptors.response.use(
  (res) => {
    if (!(res.status === 200 || res.status === 201)) {
      throw new Error();
    }

    if (res.data.errors) {
      throw new Error(res.data.errors);
    }

    return res.data;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export { authAPI };
