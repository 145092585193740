import * as React from "react";
import styled from "styled-components";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import icon from "../../../../img/web/section/section3/icon.png";
import logo1 from "../../../../img/web/section/section3/logo1.png";
import logo10 from "../../../../img/web/section/section3/logo10.png";
import logo11 from "../../../../img/web/section/section3/logo11.png";
import logo12 from "../../../../img/web/section/section3/logo12.png";
import logo13 from "../../../../img/web/section/section3/logo13.png";
import logo14 from "../../../../img/web/section/section3/logo14.png";
import logo15 from "../../../../img/web/section/section3/logo15.png";
import logo16 from "../../../../img/web/section/section3/logo16.png";
import logo2 from "../../../../img/web/section/section3/logo2.png";
import logo3 from "../../../../img/web/section/section3/logo3.png";
import logo4 from "../../../../img/web/section/section3/logo4.png";
import logo5 from "../../../../img/web/section/section3/logo5.png";
import logo6 from "../../../../img/web/section/section3/logo6.png";
import logo7 from "../../../../img/web/section/section3/logo7.png";
import logo8 from "../../../../img/web/section/section3/logo8.png";
import logo9 from "../../../../img/web/section/section3/logo9.png";

const Section3 = () => {
  const { isMobile } = useDeviceSize();

  return (
    <Section3Wrapper id="3">
      <div>
        <img src={icon} width="100px" alt="icon" />

        <div>
          <h1>
            대학생을 위한 파트너
            <br /> 엔퍼 Partners
          </h1>

          <p>200개 이상의 브랜드, 2000여개 파트너</p>
          <p>엔퍼센트 입점사는 오늘도 N% 성장중!</p>
        </div>
      </div>

      <LogoWrapper>
        <img src={logo1} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo2} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo3} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo4} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo5} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo6} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo7} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo8} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo9} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo10} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo11} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo12} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo13} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo14} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo15} width={isMobile ? "160px" : "288px"} alt="logo" />
        <img src={logo16} width={isMobile ? "160px" : "288px"} alt="logo" />
      </LogoWrapper>
    </Section3Wrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 100%;

  > img {
    margin-top: 10px;
    margin-left: 10px;
  }
`;

const Section3Wrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 150px;

  > video {
    margin-top: 40px;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    > img {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    > div {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 24px;
      font-weight: 500;
      color: #6a6f75;
      width: 100%;
      margin-left: 24px;

      > h1 {
        color: #2c2f32;
        margin: 0;
        padding-bottom: 20px;
      }

      > p {
        margin: 0;
        line-height: 33.6px;
      }

      > p:nth-child(3) {
        color: #5f7fff;
      }
    }
  }

  @media screen and (max-width: 1220px) {
    padding-top: 100px 0 0 0;

    > div {
      flex-direction: column;
      align-items: center;

      > div {
        text-align: center;
        margin-left: 0;

        > h1 {
          font-size: 24px;
          margin-top: 20px;
        }

        > p {
          font-size: 16px;
          line-height: 22.4px;
        }
      }
    }
  }
`;

export default Section3;
