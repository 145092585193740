import * as React from "react";
import styled from "styled-components";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import mobileAd from "../../../../img/mobile/section/section4/bg.png";
import ad from "../../../../img/web/section/section4/service1 .png";
import icon from "../../../../img/web/section/section5/icon.png";

const Section4 = () => {
  const { isMobile } = useDeviceSize();

  return (
    <Section4Wrapper id="4">
      <img src={isMobile ? mobileAd : ad} alt="ad" width="100%" />

      <TextWrapper>
        <p>
          <img src={icon} width="32px" alt="icon" />
          <span>위치기반 서비스</span>
        </p>

        <h1>
          소식을 <br />
          <span>N퍼센트 더 신속하게</span>
        </h1>

        <div>
          언제 어디서나 한눈에 파악할 수 있는 <br />단 N번의 터치로!
        </div>
        <br />
      </TextWrapper>
    </Section4Wrapper>
  );
};

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: auto;
  position: absolute;
  left: 20px;
  top: 180px;
  z-index: 100;
  margin-top: 150px;

  > p {
    margin: 0;
    color: #5f7fff;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;

    > span {
      margin-left: 8px;
    }
  }

  > div {
    color: #6a6f75;
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
  }

  > h1 {
    color: #2c2f32;
    font-size: 48px;

    > span {
      color: #5f7fff;
    }
  }

  @media screen and (max-width: 1220px) {
    margin: 0;
    left: 20px;
    top: 0;
    padding-top: 50px;

    > p {
      font-size: 14px;

      > img {
        width: 24px;
      }
    }

    > div {
      font-size: 16px;
      line-height: 22.4px;
    }

    > h1 {
      font-size: 24px;
    }
  }
`;

const Section4Wrapper = styled.div`
  width: 100%;
  padding-top: 100px;
  max-width: 1200px;
  margin: auto;
  position: relative;
  z-index: 10;

  @media screen and (max-width: 1220px) {
    padding: 0;

    > img {
      padding-top: 250px;
    }
  }
`;

export default Section4;
