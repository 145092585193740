import * as React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import mobileAd from "../../../../img/mobile/section/section5/ad.png";
import icon from "../../../../img/web/section/section5/icon.png";
import ad from "../../../../img/web/section/section5/phone.png";
import LottieData from "../../../../lottie/web/service2.json";

const Section5 = () => {
  const { isMobile } = useDeviceSize();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Section5Wrapper id="5">
      <img src={isMobile ? mobileAd : ad} width="50%" alt="ad" />

      <TextWrapper>
        <p>
          <img src={icon} width="32px" alt="icon" />
          <span>자유로운 소통</span>
        </p>

        <h1>
          소통을 <br />
          <span>N퍼센트 더 활발하게</span>
        </h1>

        <div>
          대학생들 간 실시간 정보공유까지 <br />단 N번의 터치로!
        </div>
        <br />
        <Lottie
          options={defaultOptions}
          height={"100%"}
          width={isMobile ? "100%" : 377}
          isClickToPauseDisabled={true}
          style={{ marginTop: "20px", margin: "0" }}
        />
      </TextWrapper>
    </Section5Wrapper>
  );
};

const TextWrapper = styled.div`
  text-align: left;
  margin: auto;

  > p {
    margin: 0;
    color: #5f7fff;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;

    > span {
      margin-left: 8px;
    }
  }

  > div {
    color: #6a6f75;
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
  }

  > h1 {
    color: #2c2f32;
    font-size: 48px;

    > span {
      color: #5f7fff;
    }
  }

  @media screen and (max-width: 1220px) {
    margin: 0;
    padding: 0;

    > p {
      font-size: 14px;

      > img {
        width: 24px;
      }
    }

    > div {
      font-size: 16px;
      line-height: 22.4px;
    }

    > h1 {
      font-size: 24px;
    }
  }
`;

const Section5Wrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin: auto;

  @media screen and (max-width: 1220px) {
    padding: 100px 0 0 0;
    flex-direction: column-reverse;

    > img {
      width: 100%;
    }

    > div {
      margin: 0 16px;
    }
  }
`;

export default Section5;
