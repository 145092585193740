import * as React from "react";
import styled from "styled-components";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import icon from "../../../../img/web/section/section6/icon.png";
import {
  default as mobileMotion,
  default as motion,
} from "../../../../img/web/section/section6/motion.png";

const Section6 = () => {
  const { isMobile } = useDeviceSize();

  return (
    <Section6Wrapper id="6">
      <TextWrapper>
        <p>
          <img src={icon} width="32px" alt="icon" />
          <span>알뜰한 소비</span>
        </p>
        <h1>
          소비를 <br />
          <span>N퍼센트 더 현명하게</span>
        </h1>
        <div>
          일상의 전반적인 부분에 유용한 혜택을
          <br />
          오직 엔퍼센트에서만 누려보세요!
        </div>
      </TextWrapper>
      <img
        src={isMobile ? mobileMotion : motion}
        alt=""
        width={isMobile ? "100%" : "792px"}
      />
    </Section6Wrapper>
  );
};

const TextWrapper = styled.div`
  text-align: left;
  margin: auto;
  width: 100%;
  box-sizing: border-box;

  > p {
    margin: 0;
    color: #5f7fff;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;

    > span {
      margin-left: 8px;
    }
  }

  > div {
    color: #6a6f75;
    font-size: 24px;
    font-weight: 400;
    line-height: 33.6px;
  }

  > h1 {
    color: #2c2f32;
    font-size: 48px;

    > span {
      color: #5f7fff;
    }
  }

  @media screen and (max-width: 1220px) {
    padding: 0 16px;
    > p {
      font-size: 14px;

      > img {
        width: 24px;
      }
    }

    > h1 {
      font-size: 24px;
      line-height: 31.2px;
    }

    > div {
      font-size: 16px;
      line-height: 22.4px;
    }
  }
`;

const Section6Wrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin: auto;

  @media screen and (max-width: 1220px) {
    padding: 100px 0 0 0;
    flex-direction: column;
  }
`;

export default Section6;
