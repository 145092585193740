import * as React from "react";
import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import img from "../../../img/lineBg.png";
import mobileImg from "../../../img/mobileLineBg.png";
import Section4 from "./section/Section4";
import Section5 from "./section/Section5";
import Section6 from "./section/Section6";

const MotionPage = () => {
  const { isMobile } = useDeviceSize();

  return (
    <MotionPageWrapper isMobile={isMobile}>
      <Section4 />
      <Section5 />
      <Section6 />
    </MotionPageWrapper>
  );
};

const MotionPageWrapper = styled.div<{ isMobile: boolean }>`
  background-image: url(${(props) => (props.isMobile ? mobileImg : img)});
  background-size: cover;
  background-color: #f8f8f8;
  width: 100%;
  position: relative;
  margin: auto;
  height: 100%;

  > img {
    width: 100%;
  }

  > div {
    z-index: 100;
    width: 100%;
  }

  @media screen and (max-width: 1220px) {
    padding: 0;
    margin: 0;
  }
`;

export default MotionPage;
