import * as React from "react";
import styled from "styled-components";
import MainBanner from "./components/MainBanner";
import MotionPage from "./components/MotionPage";
import Section1 from "./components/section/Section1";
import Section2 from "./components/section/Section2";
import Section3 from "./components/section/Section3";
import Section7 from "./components/section/Section7";

const Mainhome = () => {
  return (
    <>
      <MainBanner />

      <InnerTemplate>
        <Section1 />
        <Section2 />
        <Section3 />
      </InnerTemplate>

      <MotionPage />
      <Section7 />
    </>
  );
};

const InnerTemplate = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;

  @media screen and (max-width: 1220px) {
    padding: 0;
  }
`;

export default Mainhome;
