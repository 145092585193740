import searchParamsParse from "../../utills/searchParamsParse";
import { authAPI } from "../config";
import { PageNationProps } from "./type";

export const getNoticeList = async (search: PageNationProps) => {
  return await authAPI.get(`/notice?${searchParamsParse(search)}`);
};

export const getNoticeDetail = async (id: string) => {
  return await authAPI.get(`/notice/${id}`);
};
