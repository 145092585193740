import * as React from "react";
import styled from "styled-components";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import icon from "../../../../img/web/section/section1/icon.png";
import video from "../../../../img/web/section/section1/video.mp4";

const Section1 = () => {
  const { isMobile } = useDeviceSize();

  return (
    <Section1Wrapper id="1">
      <div>
        <img src={icon} width="100px" alt="icon" />

        <div>
          <h1>
            일상 속 현명한 소비 습관
            <br /> 엔퍼 라이프
          </h1>

          <p>오늘도 현명한 캠퍼스라이프, 엔퍼라이프</p>
          {isMobile ? (
            <p>
              업종 제한 없이 생활에 필요한
              <br /> 모든 것들을 한 번에!
            </p>
          ) : (
            <p>업종 제한 없이 생활에 필요한 모든 것들을 한 번에!</p>
          )}
        </div>
      </div>

      {/**@ts-ignore */}
      <video muted autoPlay loop width="100%">
        <source src={video} type="video/mp4" />
      </video>
    </Section1Wrapper>
  );
};

const Section1Wrapper = styled.div`
  width: 100%;
  padding-top: 100px;

  > video {
    margin-top: 40px;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    > img {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    > div {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 24px;
      font-weight: 500;
      color: #6a6f75;
      width: 100%;
      margin-left: 24px;

      > h1 {
        color: #2c2f32;
        margin: 0;
        padding-bottom: 20px;
      }

      > p {
        margin: 0;
        line-height: 33.6px;
      }

      > p:nth-child(2) {
        color: #5f7fff;
      }
    }
  }

  @media screen and (max-width: 1220px) {
    > div {
      flex-direction: column;
      align-items: center;

      > img {
        margin-bottom: 16px;
      }

      > div {
        display: flex;
        text-align: center;
        margin-left: 0;

        > h1 {
          font-size: 24px;
        }

        > p {
          font-size: 16px;
          line-height: 22.4px;
        }

        > p:nth-child(2) {
          color: #5f7fff;
        }
      }
    }
  }
`;

export default Section1;
