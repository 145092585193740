import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import RoutesPage from "./routes";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Header />
        <RoutesPage />
        <Footer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
