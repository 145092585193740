import { Pagination } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import * as React from "react";
import { styled } from "styled-components";
import { getNoticeList } from "../../api/notice";
import NoticeList from "./components/NoticeList";

const Notice = () => {
  const [search, setSearch] = React.useState({
    page: "1",
    limit: "10",
    type: 0,
  });
  const {
    data: noiticeList,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["notice"],
    queryFn: () => getNoticeList(search),
    enabled: !!search,
  });

  const [curPage, setCurPage] = React.useState((noiticeList as any)?.nowPage);
  React.useEffect(() => {
    refetch();
  }, [search, curPage]);

  const onChangePage = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setCurPage(value);
    setSearch({ ...search, page: value });
  };

  return (
    <NoticeWrapper>
      {isLoading ? (
        <LoadingArea />
      ) : (
        <NoticeList data={noiticeList?.data as any[]} />
      )}

      {noiticeList?.data?.length !== 0 && (
        <PagenationWrapper>
          <Pagination
            color="primary"
            count={(noiticeList as any)?.totalPage}
            page={+curPage || 1}
            variant="outlined"
            onChange={onChangePage as any}
          />
        </PagenationWrapper>
      )}
    </NoticeWrapper>
  );
};
export default Notice;

const LoadingArea = styled.div`
  width: 100%;
  height: 1200px;
`;

const PagenationWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  width: 100%;
  justify-content: center;
  margin: auto;
  align-items: center;
`;

const NoticeWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  flex-direction: column;
  height: 60vh;

  @media screen and (max-width: 1220px) {
    width: 100%;
    display: flex;
    padding: 100px 0 0 0;
  }
`;
