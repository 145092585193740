import * as React from "react";
import { useState } from "react";
import { styled } from "styled-components";

interface FaqItemProps {
  ask: string;
  answer: string;
}

const FaqItem = ({ ask, answer }: FaqItemProps) => {
  const [isActive, setIsActive] = useState(false);

  const onHandleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <FaqItemWrapper
      onClick={onHandleActive}
      className={`${isActive ? "active" : ""}`}
    >
      <div>
        <p>Q.</p>
        <p>{ask}</p>
      </div>

      {isActive && <AnswerWrapper>{answer}</AnswerWrapper>}
    </FaqItemWrapper>
  );
};

const AnswerWrapper = styled.div`
  color: #6a6f75;
  font-size: 16px;
  line-height: 25.6px;
  text-align: left;
  margin-top: 16px;
  @media screen and (max-width: 1220px) {
    font-size: 14px;
    line-height: 23.6px;
  }
`;

const FaqItemWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #ffffff;

  > div {
    display: flex;
    > p {
      color: #2c2f32;
      font-size: 24px;
      font-weight: 500;
      line-height: 33.6px;
      margin: 0;
      text-align: left;
    }

    > p + p {
      margin-left: 10px;
    }
  }

  > div {
    color: #6a6f75;
    font-size: 16px;
    line-height: 25.6px;
    text-align: left;
  }

  &:hover {
    border: 1px solid #b7c7ff;

    > p {
      color: #5f7fff;
    }
  }

  &.active {
    border: 1px solid #ebeef1;
  }

  @media screen and (max-width: 1220px) {
    padding: 12px;
    box-sizing: border-box;
    display: flex;

    > div {
      > p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export default FaqItem;
