import * as React from "react";
import { Route, Routes } from "react-router-dom";
import Faqs from "../pages/faqs/Faqs";
import Mainhome from "../pages/home/Mainhome";
import Notice from "../pages/notice/Notice";
import NoticeDetail from "../pages/notice/components/NoticeDetail";

const RoutesPage = () => {
  return (
    <Routes>
      {/** 메인홈 */}
      <Route path="/" element={<Mainhome />} />

      {/** 공지사항 */}
      <Route path="/notice" element={<Notice />} />
      <Route path="/notice/:id" element={<NoticeDetail />} />

      {/** 자주 묻는 질문 */}
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/faqs/:tab" element={<Faqs />} />
    </Routes>
  );
};

export default RoutesPage;
