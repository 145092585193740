import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";

const faqTabs = [
  {
    url: "/faqs/auth",
    name: "인증",
  },
  {
    url: "/faqs/service",
    name: "내 계정",
  },
  {
    url: "/faqs/pay",
    name: "결제",
  },
  {
    url: "/faqs/report",
    name: "신고",
  },
  {
    url: "/faqs/community",
    name: "커뮤니티",
  },
];

const Tabs = () => {
  const { pathname } = useLocation();

  return (
    <TabsWrapper>
      {faqTabs.map((el) => (
        <Tab>
          <Link className={el.url === pathname ? "active" : ""} to={el.url}>
            {el.name}
          </Link>
        </Tab>
      ))}
    </TabsWrapper>
  );
};

const Tab = styled.div`
  cursor: pointer;
  > a {
    text-decoration: none;
    color: #6a6f75;
    font-size: 24px;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 600;

    &.active {
      color: #5f7fff;
      background-color: #e8edff;
    }

    &:hover {
      background-color: #e8edff;
    }
  }

  @media screen and (max-width: 1220px) {
    > a {
      font-size: 16px;
      padding: 10px;
      min-width: 60px;
    }
  }
`;

const TabsWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d1d4d6;

  > div + div {
    margin-left: 16px;
  }

  @media screen and (max-width: 1220px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    display: flex;
    overflow: auto;

    > div + div {
      margin: 0;
    }

    > div {
      margin-left: 3px;
      width: fit-content;
    }
  }
`;

export default Tabs;
