import * as React from "react";
import styled from "styled-components";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import mobileAd from "../../../../img/mobile/section/section2/recipt.png";
import icon from "../../../../img/web/section/section2/icon.png";
import ad from "../../../../img/web/section/section2/recipt.png";

const Section2 = () => {
  const { isMobile } = useDeviceSize();

  return (
    <Section2Wrapper id="2">
      <img
        src={isMobile ? mobileAd : ad}
        width={isMobile ? "100%" : "50%"}
        alt="ad"
      />

      <div>
        <div>
          <img src={icon} width={"100px"} alt="icon" />

          <div>
            <h1>
              지출 걱정
              <br /> 엔퍼센트 덜어내기
            </h1>

            <p>대학생 혜택 하나! 엔퍼센트 할인까지 둘!</p>
            <p>
              대학생활에 유용한 혜택과 할인,정보를 <br />
              엔퍼센트 하나로 모두 챙기자!
            </p>
          </div>
        </div>
      </div>
    </Section2Wrapper>
  );
};

const Section2Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 100px;

  > img {
    margin-top: 40px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      > img {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      > div {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: 24px;
        font-weight: 500;
        color: #6a6f75;
        width: 100%;
        margin-left: 24px;
        align-items: flex-start;

        > h1 {
          color: #2c2f32;
          margin: 0;
          padding-bottom: 20px;
        }

        > p {
          margin: 0;
          line-height: 33.6px;
        }

        > p:nth-child(2) {
          color: #5f7fff;
        }
      }
    }
  }

  @media screen and (max-width: 1220px) {
    flex-direction: column-reverse;

    > img {
      width: 100%;
      margin: 0;
    }

    > div {
      > div {
        flex-direction: column;
        align-items: center;

        > div {
          text-align: center;
          margin-left: 0;
          align-items: center;

          > h1 {
            font-size: 24px;
          }

          > p {
            font-size: 16px;
            line-height: 22.4px;
          }
        }
      }
    }
  }
`;

export default Section2;
