import { useQuery } from "@tanstack/react-query";
import * as dayjs from "dayjs";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { getNoticeDetail } from "../../../api/notice";
import arrow from "../../../img/web/notice/arrow-left.png";

const NoticeDetail = () => {
  const { id } = useParams();

  const {
    data: noiticeInfo,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ["info"],
    queryFn: () => getNoticeDetail(id),
  });

  React.useEffect(() => {
    refetch();
  }, [id]);

  const date = dayjs(noiticeInfo?.data?.date).format("YYYY-MM-DD");
  return (
    <>
      {isLoading && <LoadingArea />}

      {isSuccess && (
        <NoticeDetailWrapper>
          <Link to="/notice">
            <button>
              <img src={arrow} alt="arrow" width="24px" />
              목록으로
            </button>
          </Link>

          <h1>{noiticeInfo.data?.title || "-"}</h1>
          <p>{date || "-"}</p>
          <div>{noiticeInfo.data?.text || "-"}</div>

          {noiticeInfo.data.image && (
            <img src={noiticeInfo.data.image.url} width="100%" alt="img" />
          )}
        </NoticeDetailWrapper>
      )}
    </>
  );
};
export default NoticeDetail;

const LoadingArea = styled.div`
  width: 100%;
  height: 1200px;
`;

const NoticeDetailWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  padding: 80px 0 200px 0;
  max-width: 1200px;
  margin: auto;
  height: 100vh;

  > a {
    text-decoration: none;
    > button {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border: 0;
      background: #ffffff;
      color: #a3a8ac;
      font-size: 14px;
      padding: 8px 24px;
      border-radius: 20px;

      &:hover {
        background-color: #f3f5f9;
        color: #41454a;
        font-weight: 500;
        > img {
          color: #41454a;
        }
      }
    }
  }

  > div {
    text-align: left;
    color: #41454a;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    margin-top: 32px;
  }

  > p {
    color: #a3a8ac;
    font-weight: 500;
    margin: 0;
    margin-bottom: 20px;
  }

  > img {
    margin-top: 20px;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 1220px) {
    width: 100%;
    padding: 0;
    padding-bottom: 50px;
    padding-top: 50px;

    > a {
      margin-top: 20px;
      margin-left: 16px;
    }

    > img {
      padding: 0 20px;
    }

    > h1 {
      padding: 0 20px;
    }

    > p {
      padding: 0 20px;
    }

    > div {
      font-size: 16px;
      font-weight: 400;
      margin-top: 0;
      padding: 0 20px;
    }
  }
`;
