export const serviceData = [
  {
    ask: "아이디/ 비밀번호를 잃어버렸어요.",
    answer: `엔퍼센트는 개인정보처리방침과 개인정보보호법에 의거해 개인정보를 회원 개인에게 직접적 알려드릴 수 없습니다. ’아이디/비밀번호 찾기’ 를 통해서 직접 계정을 찾으실 수 있습니다. 이메일 분실로 아이디 찾기의 진행이 어려운 경우, 계정을 새로 만들어 주셔야 합니다.계정을 새로 만들 경우, 기존 계정 정보의 복구는 어렵습니다.`,
  },
  {
    ask: "닉네임, 프로필 이미지 등의 프로필 변경을 어떻게 하나요?",
    answer: `우측 하단 [마이페이지] 클릭>[닉네임]클릭>[프로필 설정] 탭을 통해서 변경 가능합니다.`,
  },
  {
    ask: "회원 탈퇴, 로그아웃은 어떻게 하나요?",
    answer: `우측 하단 [마이페이지]클릭>[닉네임]클릭>[프로필 설정] 탭 하단의 버튼을 통해서 가능합니다.`,
  },
  {
    ask: "가입 초기 설정했던 키워드 변경은 어떻게 하나요?",
    answer: `우측 하단 [마이페이지]클릭>[닉네임]클릭>[프로필 설정] 탭 중간의 키워드 변경 버튼을 통해 가능합니다.`,
  },
  {
    ask: "회원 가입 진행 시 본인 인증 과정에서 오류가 발생합니다.",
    answer: `생년월일 8 자리, 성별, 휴대폰 번호 등의 정보를 정확하게 입력해야 인증이 가능합니다.
[오류 코드 별 내용]

-오류 XXXX,XXXx: 향후 추가
-오류 abcd, efgh: 향후 추가`,
  },
];

export const authData = [
  {
    ask: "학교/ 캠퍼스가 변경된 경우는 어떻게 하나요?",
    answer: `엔퍼센트에서 회원가입 시 선택한 학교/캠퍼스는 변경할 수 없습니다. 변경을 원하시는 경우에는 현재 이용중인 계정을 탈퇴하고 다시 회원가입 해주시면 됩니다.`,
  },
  {
    ask: "학교 인증 시 첨부했던 자료의 개인정보는 안전하게 관리되나요?",
    answer: `입력하신 모든 개인정보는 SSL(HTTPS) 방식으로 암호화되어 처리됩니다.추가로 인증 처리 이후, 첨부해주신 자료에 대해서는 개인정보처리방침에 의거하여 안전하게 처리됩니다.증명 자료에서는 ‘학교, 이름, 학번, 수험번호’ 등을 제외한 ‘카드번호, 생년월일, 사진’ 등 의     개인정보는 가린 후 제출해 주셔야 합니다. 그렇지 않을 경우 자료의 인증이 처리되지 않을 수 있습니다. `,
  },
  {
    ask: "재학 증명서가 무엇인지 알려주세요.",
    answer: `재학증명서란 각 대학교에서 공식적으로 발급하는 재학,휴학,졸업 여부를 포함하는 증명서 입니다. 발급 방법은 각 학교 홈페이지에 방문해 확인 부탁 드립니다. 증명서에 발급일자, 이름, 학번 의 정보가 표시되지 않을 경우, 본인 확인이 어려워 인증 시 자료로 이용이 제한될 수 있습니다. `,
  },
  {
    ask: "인증 자료가 개명하기 전의 이름으로 발급이 됐어요.",
    answer: `학교 인증 진행 과정에서 인증 자료를 첨부할 때, 인증 자료와 개명 증명서를 하나의 사진으로 만들어서 첨부해주시면 됩니다.개명 증명서: 개명을 확인할 수 있는 기본 증명서 or 주민등록초본`,
  },
  {
    ask: "파일 첨부 시 “첨부할 수 없는 파일입니다’ 라는 오류가 발생합니다.",
    answer: `엔퍼센트에서는 JPG/PNG 등의 이미지 파일만 첨부할 수 있습니다.해당 이미지 파일을 첨부해도 동일한 오류가 반복될 경우 용량 압축, 크기 조절등의 작업을 거친 후 다시 업로드해 주시기 바랍니다.  `,
  },
];

export const payData = [
  {
    ask: "작성했던 리뷰의 수정, 삭제는 어떻게 하나요?",
    answer: `우측 하단 [마이페이지]클릭>[나의 리뷰 관리]클릭을 통해서 관리 가능합니다.`,
  },
  {
    ask: "쿠폰과 포인트는 어디서 확인 하나요?",
    answer: `우측 하단 [마이페이지]클릭>[쿠폰 받기/내 쿠폰], [포인트] 탭을 통해서 확인 가능합니다. `,
  },
  {
    ask: "엔퍼센트에서 사용 가능한 결제 수단에는 어떤 것들이 있나요? ",
    answer: `엔퍼센트에서 사용 가능한 결제 수단에는 신용/체크 카드, 등이 있습니다.`,
  },
  {
    ask: "구매 내역 조회, 삭제는 어디서 하나요?",
    answer: `우측 하단[나의 구매]탭을 통해서 조회가 가능하고 [완료 내역 삭제]버튼을 통해서 
삭제가 가능합니다. `,
  },
  {
    ask: "쿠폰과 포인트 사용이 제한된다고 나와요.",
    answer: `구매 방식 중 현장 결제를 선택하실 경우 쿠폰과 포인트 사용이 제한됩니다

인앱결제를 사용해야 쿠폰과 포인트 사용이 가능합니다.`,
  },
];

export const reportData = [
  {
    ask: "신고는 어떤 기능인지 설명해주세요.",
    answer: `신고는 엔퍼센트 커뮤니티 이용 규칙에 어긋나는 욕설, 도배,  등의 부적절한 게시물을 신고할 수 있는 기능입니다. 신고를 받은 게시물은 현행 법률 혹은 서비스 이용 약관 및 커뮤니티 이용 규칙을 위반한 경우에 대하여, 정해진 기준에 따라 게시물 삭제, 서비스 이용 제한 등의 조치를 취하고 있습니다. `,
  },
  {
    ask: "장난/ 실수로 신고를 접수했는데 어떻게 하나요.",
    answer: `신고가 접수된 이후에는 취소가 어려워 신중하게 해당 기능을 사용해주세요.`,
  },
  {
    ask: "서비스를 이용하려는데 이용이 제한되었다고 나와요. ",
    answer: `엔퍼센트는 방송통신심의위원회의 정보통신에 관한 심의 규정, 현행 법률, 서비스 이용 약관 및 커뮤니티 이용 규칙을 위반하거나 타 이용자에게 악영향을 끼친 경우에 대하여 
게시물 삭제, 서비스 이용 제한 등의 조치를 취하고 있습니다. 
엔퍼센트는 모든 대학생들이 기분 좋게 참여하는 커뮤니티를 만들기 위해 이용 규칙을 
제정하여 운영 중 입니다. 모든 이용자는 반드시 커뮤니티 이용 규칙의 모든 내용을 
숙지하여야 합니다.   `,
  },
  {
    ask: "명예훼손, 저작권 침해 등의 소지가 있는 게시물에 대한 삭제 요청  어떻게 하나요?",
    answer: `해당 게시 글 우측 상단의 신고 버튼을 통해서 신고 접수해 주시면 
정보 통신망 이용 촉진 등 정보 보호 등에 관한 법률에 의거하여 권리 침해 사실 확인 후

게시 글 삭제 및 서비스 이용 제한 등의 조치를 취해드립니다.`,
  },
  {
    ask: " 채팅 차단 및 신고는 어떻게 하나요?",
    answer: `채팅 화면 우측 상단의 점3개 버튼을 클릭하여 채팅 나가기/ 차단하기/ 신고하기 
가능합니다.`,
  },
];

export const communityData = [
  {
    ask: "알림 on/off 설정은 어떻게 하나요?",
    answer: `우측 상단 [종]버튼 클릭을 통해서 푸시알림, 이벤트 및 할인 혜택, 채팅, 댓글/답글 알림의 on/off설정을 할 수 있습니다.`,
  },
  {
    ask: "사진을 첨부하려는데 사진이 보이지 않아요. ",
    answer: `스마트폰 설정에서 엔퍼센트 앱의 사진 권한을 켜주시기 바랍니다. `,
  },
  {
    ask: "삭제된 글, 댓글을 복구할 수 있나요?",
    answer: `아쉽지만, 삭제된 글, 댓글은 엔퍼센트 서버에서도 삭제돼서 복구가 불가능합니다.`,
  },
  {
    ask: "자신이 쓴 글, 댓글, 채팅은 어디서 볼 수 있나요?",
    answer: `하단의 [커뮤니티]클릭>우측 상단[사람]버튼 클릭>[나의 글] 탭을 통해서 확인 가능합니다. `,
  },
  {
    ask: " 찜, 스크랩 했던 컨텐츠는 어디서 볼 수 있나요?",
    answer: `우측 하단 [마이페이지]클릭>[찜/스크랩]클릭을 통해서 확인 할 수 있습니다.`,
  },
];
