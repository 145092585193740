import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useDeviceSize from "../../../../hooks/useDeviceSize";
import mobileBg from "../../../../img/mobile/section/section7/bg.png";
import apple from "../../../../img/web/section/section7/apple.png";
import google from "../../../../img/web/section/section7/google.png";
import bg from "../../../../img/web/section/section7/main.png";

const Section7 = () => {
  const { isMobile } = useDeviceSize();

  return (
    <Section7Wrapper id="7" isMobile={isMobile}>
      <div>
        {isMobile ? (
          <h1>
            알뜰하고 현명하게
            <br /> 대학생활 하자!
          </h1>
        ) : (
          <h1>알뜰하고 현명하게 대학생활 하자!</h1>
        )}
        <p>전국 300만 대학생 필수 라이프스타일 앱, 엔퍼센트</p>

        <div>
          <Link to="https://apps.apple.com/kr/app/%EC%97%94%ED%8D%BC%EC%84%BC%ED%8A%B8/id1611746590">
            <img src={apple} width="40px" alt="apple logo" />
            App Store
          </Link>

          <Link to="https://play.google.com/store/apps/details?id=com.limefriends.abm_android&hl=ko&gl=US&pli=1">
            <img src={google} width="40px" alt="google logo" />
            Google Play
          </Link>
        </div>
      </div>
    </Section7Wrapper>
  );
};

const Section7Wrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin: auto;
  border: 0;
  background-image: url(${(props) => (props.isMobile ? mobileBg : bg)});
  height: 510px;
  background-size: cover;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    > h1 {
      color: white;
      font-size: 56px;
      margin: 0;
      font-weight: 500;
    }

    > p {
      color: white;
      font-size: 32px;
      margin: 0;
      margin: 16px 0;
    }

    > div {
      display: flex;
      z-index: 100;

      > a {
        background-color: #1c1c1ccc;
        border: 0;
        margin: 10px;
        padding: 10px 30px;
        border-radius: 100px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 1220px) {
    margin: auto;
    height: 100%;
    padding: 30px 0;
    border-bottom: 0;

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      > h1 {
        font-size: 24px;
        line-height: 31.2px;
      }

      > p {
        font-size: 16px;
      }

      > div {
        display: flex;
        z-index: 100;

        > a {
          background-color: #1c1c1ccc;
          border: 0;
          margin: 10px;
          padding: 8px 20px;
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;

          > img {
            width: 24px;
          }
        }
      }
    }
  }
`;

export default Section7;
