import * as dayjs from "dayjs";
import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { NoticeData } from "../../../api/notice/type";

const NoticeItem = ({ objectId, notice_title, date }: NoticeData) => {
  const dateFormat = dayjs(date).format("YYYY-MM-DD");

  return (
    <NoticeItemWrapper to={`/notice/${objectId}`}>
      <p>{notice_title || "-"}</p>
      <span>{dateFormat || "-"}</span>
    </NoticeItemWrapper>
  );
};

const NoticeItemWrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 10px;
  font-size: 24px;
  border: 1px solid #ffffff;
  box-sizing: border-box;

  > p {
    color: #2c2f32;
    margin: 0;
  }

  > span {
    color: #a3a8ac;
    font-size: 20px;
  }

  &:hover {
    border: 1px solid #b7c7ff;

    > p {
      color: #5f7fff;
    }
  }

  @media screen and (max-width: 1220px) {
    flex-direction: column;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;

    > p {
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 250px;
    }

    > span {
      font-size: 12px;
      line-height: 19.2px;
    }
  }
`;

export default NoticeItem;
