import * as React from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import styled from "styled-components";
import useDeviceSize from "../../../hooks/useDeviceSize";
import mobileVedio from "../../../img/mobile/mainbanner.mp4";
import video from "../../../img/web/mainbanner.mp4";
import apple from "../../../img/web/section/section7/apple.png";
import google from "../../../img/web/section/section7/google.png";
import LottieData from "../../../lottie/web/next.json";

const MainBanner = () => {
  const { isMobile } = useDeviceSize();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
  };

  return (
    <MainBannerWrapper>
      <ContentWrapper>
        <div>
          <div>
            <p>대학생활의</p>
            <p>모든 것을 편리하게</p>
            <p>엔퍼센트</p>
          </div>

          <p>전국 300만 대학생 필수 라이프스타일 앱</p>

          <ButtonWrapper>
            <Link
              target="_blank"
              to="https://apps.apple.com/kr/app/%EC%97%94%ED%8D%BC%EC%84%BC%ED%8A%B8/id1611746590"
            >
              <img
                src={apple}
                width={isMobile ? "20px" : "40px"}
                alt="apple logo"
              />
              App Store
            </Link>

            <Link
              target="_blank"
              to="https://play.google.com/store/apps/details?id=com.limefriends.abm_android&hl=ko&gl=US&pli=1"
            >
              <img
                src={google}
                width={isMobile ? "20px" : "40px"}
                alt="google logo"
              />
              Google Play
            </Link>
          </ButtonWrapper>
        </div>
      </ContentWrapper>
      <video muted autoPlay loop width={"100%"}>
        <source src={isMobile ? mobileVedio : video} type="video/mp4" />
      </video>
      <ScrollLink to="1" smooth={true}>
        <Lottie
          options={defaultOptions}
          width={"56px"}
          height={"116px"}
          isClickToPauseDisabled={true}
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
          }}
        />
      </ScrollLink>
    </MainBannerWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  > a {
    background-color: #1c1c1ccc;
    border: 0;
    margin: 10px;
    padding: 10px 24px;
    border-radius: 100px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    width: 184px;
    height: 50px;
  }

  @media screen and (max-width: 1220px) {
    margin: 0;
    > a {
      font-size: 12px;
      font-weight: 700;
      line-height: 19.6px;
      font-weight: 500;
      width: 168px;
      height: 40px;
      padding: 0 5px;
      text-decoration: none;

      > img {
        width: 16px;
        margin-right: 1px;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  margin: auto;
  z-index: 100;
  max-width: 1200px;
  padding: 0 80px;
  position: relative;

  > div {
    position: absolute;
    top: 300px;

    > p {
      margin: 0;
      color: #2c2f32;
      font-size: 32px;
      line-height: 44.8px;
      margin-top: 16px;
    }

    > div {
      color: #2c2f32;
      font-size: 56px;
      font-weight: 600;
      line-height: 72.8px;
      text-align: left;

      > p {
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 1220px) {
    > div {
      background-color: aliceblue;
      bottom: 0;
      > div {
        font-size: 32px;
        line-height: 44.8px;
        text-align: center;
      }

      > p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
`;

const MainBannerWrapper = styled.div`
  width: 100%;

  @media screen and (max-width: 1220px) {
    height: 100%;

    > video {
      min-height: 500px;
      height: 100%;
    }

    > div {
      width: fit-content;

      > div {
        top: 100px;
        right: -50px;

        > div {
          width: 260px;
        }
      }
    }
  }
`;

export default MainBanner;
